import React from "react";
import "../CSS/checkbox.css";

const Checkbox = ({
  handleBukietChange,
  bukiet,
  setBukiet,
  checkboxError,
  butonierka,
  setButonierka,
  handleButonierkaChange,
  bransoletka,
  setBransoletka,
  handleBransoletkaChange,
  wianek,
  handleWianekChange,
  setWianek,
  dekoracjaKosciola,
  setDekoracjaKosciola,
  handleDekoracjaKosciolaChange,
  dekoracjaSali,
  setDekoracjaSali,
  handleDekoracjaSaliChange,
}) => {
  return (
    <div className="app__form-checkbox">
      <div className="app__form_heading-container">
        <p className="app__form-checkbox_heading">Interesuje Nas</p>
        <div className="app__form-checkbox_underscore"></div>
      </div>
      <div className="app__form-checkbox_content-container">
        <div className="app__form-checkbox_flower-container">
          <div className="form__choices">
            <label className="form__choices-checkbox">
              <input
                onChange={handleBukietChange}
                name="bukiet_slubny"
                value="bukiet slubny"
                type="checkbox"
                onClick={() => setBukiet(!bukiet)}
              />
              <span className="checkbox"></span>
              <p className="choices__text small">bukiet ślubny</p>
            </label>
          </div>
          <div className="form__choices">
            <label className="form__choices-checkbox">
              <input
                name="butonierki"
                value="butonierki"
                type="checkbox"
                onChange={handleButonierkaChange}
                onClick={() => setButonierka(!butonierka)}
              />
              <span className="checkbox"></span>
              <p className="choices__text small">butonierki</p>
            </label>
          </div>
          <div className="form__choices">
            <label className="form__choices-checkbox">
              <input
                name="bransoletka"
                value="bukiet/bransoletka"
                type="checkbox"
                onChange={handleBransoletkaChange}
                onClick={() => setBransoletka(!bransoletka)}
              />
              <span className="checkbox"></span>
              <p className="choices__text small long-text">
                bukiet/bransoletka dla świadkowej
              </p>
            </label>
          </div>
          <div className="form__choices">
            <label className="form__choices-checkbox">
              <input
                name="wianek"
                value="wianek"
                type="checkbox"
                onChange={handleWianekChange}
                onClick={() => setWianek(!wianek)}
              />
              <span className="checkbox"></span>
              <p className="choices__text small">wianek</p>
            </label>
          </div>
        </div>
        <div className="app__form-checkbox_decoration-container">
          <div className="form__choices">
            <label className="form__choices-checkbox">
              <input
                name="dekoracja_sali"
                value="dekoracja sali"
                type="checkbox"
                onChange={handleDekoracjaSaliChange}
                onClick={() => setDekoracjaSali(!dekoracjaSali)}
              />
              <span className="checkbox"></span>
              <p className="choices__text">Dekoracja sali</p>
            </label>
          </div>
          <div className="form__choices">
            <label className="form__choices-checkbox">
              <input
                name="dekoracja_kosciola"
                value="dekoracja kosciola"
                type="checkbox"
                onChange={handleDekoracjaKosciolaChange}
                onClick={() => setDekoracjaKosciola(!dekoracjaKosciola)}
              />
              <span className="checkbox"></span>
              <p className="choices__text">Dekoracja kościoła</p>
            </label>
          </div>
        </div>
      </div>
      <p className="error">{checkboxError}</p>
    </div>
  );
};

export default Checkbox;
