import React, { useEffect } from "react";
import images from "../../constants/images";
import "../CSS/hero.css";

const Hero = () => {
  return (
    <div className="app__hero" id="home">
      <div className="app__hero-content">
        <div className="app__hero-content_image-container">
          <img
            className="app__hero-logo_image"
            src={images.icon}
            alt="logo-icon"
          />
        </div>
        <div className="app__hero-content_text-container">
          <h1 className="app__hero-content_text">
            Najlepszy sposób, by wyraźić emocje.
          </h1>
        </div>
      </div>
      <img
        className="app__hero-decoration_image-right"
        src={images.decoration_extra}
        alt=""
      />
      <img
        className="app__hero-decoration_image-left"
        src={images.decoration_extra}
        alt=""
      />
    </div>
  );
};

export default Hero;
