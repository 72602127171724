import React from "react";
import { MdAlternateEmail } from "react-icons/md";
import { AiFillHome, AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { BsPinterest, BsPhoneFill } from "react-icons/bs";
import "../CSS/footer.css";

const Footer = () => {
  return (
    <div className="app__footer" id="footer">
      <div className="app__footer-heading_container" data-aos="fade-up">
        <h1 className="app__footer_heading">Kontakt</h1>
        <div className="app__footer-underscore"></div>
      </div>
      <div className="app__footer-content" data-aos="fade-up">
        <div className="app__footer-address">
          <div className="app__footer-inner_address">
            <div className="app__icon-container">
              <AiFillHome size={24} color="fff" className="footer-icon" />
            </div>
            <div className="app__footer-inner_content">
              <p className="app__footer-address_line top">Adres kwiaciarni:</p>
              <p className="app__footer-address_line">Plac Jana Pawła II 3</p>
              <p className="app__footer-address_line">87-200 Wąbrzeźno</p>
            </div>
          </div>
          <div className="app__footer-inner_phone">
            <div className="app__icon-container">
              <BsPhoneFill size={24} color="fff" className="footer-icon" />
            </div>
            <a
              href="tel:+48 793 383 862"
              className="app__footer-address_number"
            >
              Tel. 793 383 862
            </a>
          </div>
        </div>
        <div className="app__footer-social">
          <div className="app__footer-social_element">
            <AiFillFacebook size={24} color="fff" className="footer-icon" />
            <a
              href="https://www.facebook.com/azalia.kwiaciarnia"
              className="app__footer-link"
              target="_blank"
            >
              Facebook
            </a>
          </div>
          <div className="app__footer-social_element">
            <AiFillInstagram size={24} color="fff" className="footer-icon" />
            <a
              href="https://www.instagram.com/azaliakwiaciarnia/"
              className="app__footer-link"
              target="_blank"
            >
              Instagram
            </a>
          </div>
          <div className="app__footer-social_element">
            <BsPinterest size={24} color="fff" className="footer-icon" />
            <a
              href="https://pl.pinterest.com/studioazalia/"
              className="app__footer-link"
              target="_blank"
            >
              Pinterest
            </a>
          </div>
          <div className="app__footer-social_element">
            <MdAlternateEmail size={24} color="fff" className="footer-icon" />
            <a
              href="mailto:kontakt@kwiaciarniaazalia.pl"
              className="app__footer-link"
            >
              kontakt@kwiaciarniaazalia.pl
            </a>
          </div>
        </div>
      </div>
      <div className="app__rights-container">
        <div className="app__footer-underscore"></div>

        <div className="app__footer-rights">
          Copyright © 2023 Azalia |{" "}
          <a
            className="rights-link"
            href="https://www.linkedin.com/in/jan-kukielski/"
            target="_blank"
          >
            Created by{` Jan Kukielski`}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
