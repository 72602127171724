import logo from "../assets/logo.png";
import hero from "../assets/hero.jpg";
import icon from "../assets/icon.png";
import decoration from "../assets/decoration.png";
import decoration_extra from "../assets/decoration-extra.png";
import about from "../assets/about.jpg?as=webp";
import carousel_one from "../assets/carousel_one.jpg?as=webp";
import carousel_two from "../assets/carousel_two.jpg?as=webp";
import carousel_three from "../assets/carousel_three.jpg?as=webp";
import carousel_four from "../assets/carousel_four.png?as=webp";
import carousel_five from "../assets/carousel_five.png?as=webp";
import carousel_six from "../assets/carousel_six.png?as=webp";
import carousel_seven from "../assets/carousel_seven.png?as=webp";
import carousel_eight from "../assets/carousel_eight.jpg?as=webp";
import carousel_nine from "../assets/carousel_nine.png?as=webp";
import carousel_ten from "../assets/carousel_ten.jpg?as=webp";
import carousel_eleven from "../assets/carousel_eleven.jpg?as=webp";
import carousel_twelve from "../assets/carousel_twelve.jpg?as=webp";
import carousel_thirteen from "../assets/carousel_thirteen.jpg?as=webp";
import carousel_fourteen from "../assets/carousel_fourteen.png?as=webp";
import wedding_one from "../assets/wedding_one.jpg?as=webp";
import wedding_two from "../assets/wedding_two.jpg?as=webp";
import wedding_three from "../assets/wedding_three.jpg?as=webp";
import wedding_carousel_three from "../assets/wedding_carousel_three.png?as=webp";
import wedding_carousel_two from "../assets/wedding_carousel_two.png?as=webp";
import wedding_carousel_one from "../assets/wedding_carousel_one.jpg?as=webp";
import landscape_1 from "../assets/landscape_1.png?as=webp";
import landscape_2 from "../assets/landscape_2.jpg?as=webp";
import landscape3 from "../assets/landscape3.jpg?as=webp";
import landscape4 from "../assets/landscape4.jpg?as=webp";
import landscape5 from "../assets/landscape5.jpg?as=webp";
import landscape6 from "../assets/landscape6.jpg?as=webp";
import landscape7 from "../assets/landscape7.jpg?as=webp";
import landscape8 from "../assets/landscape8.jpg?as=webp";
import landscape9 from "../assets/landscape9.jpg?as=webp";
import portrait_one from "../assets/portrait_one.jpg?as=webp";
import portrait_two from "../assets/portrait_two.png?as=webp";
import portrait_three from "../assets/portrait_three.jpg?as=webp";
import portrait_four from "../assets/portrait_four.png?as=webp";
import portrait_5 from "../assets/portrait_5.png?as=webp";
import portrait_6 from "../assets/portrait_6.png?as=webp";

export default {
  logo,
  hero,
  icon,
  decoration,
  decoration_extra,
  about,
  carousel_one,
  carousel_two,
  carousel_three,
  carousel_four,
  carousel_five,
  carousel_six,
  carousel_seven,
  carousel_eight,
  carousel_nine,
  carousel_ten,
  carousel_eleven,
  carousel_twelve,
  carousel_thirteen,
  carousel_fourteen,
  wedding_one,
  wedding_two,
  wedding_three,
  wedding_carousel_one,
  wedding_carousel_two,
  wedding_carousel_three,
  landscape_1,
  landscape_2,
  landscape3,
  landscape4,
  landscape5,
  landscape6,
  landscape7,
  landscape8,
  landscape9,
  portrait_one,
  portrait_two,
  portrait_three,
  portrait_four,
  portrait_6,
  portrait_5,
};
