import React from "react";
import images from "../../constants/images";
import { Carousel } from "react-bootstrap";
import "../CSS/weddingCarousel.css";

const WeddingCarousel = () => {
  return (
    <div className="app__wedding-carousel" data-aos="fade-up">
      <img
        src={images.decoration_extra}
        alt=""
        className="app__wedding-carousel_decoration"
      />
      <img
        src={images.decoration_extra}
        alt=""
        className="app__wedding-carousel_decoration-two"
      />
      <div className="app__wedding-carousel_heading-container">
        <h1 className="app__wedding-carousel_heading">Nasze prace</h1>
        <div className="app__wedding-carousel_underscore"></div>
      </div>
      <Carousel fade className="carousel">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.wedding_carousel_one}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.wedding_carousel_three}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.wedding_carousel_two}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape_1}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape_2}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape4}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape5}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape6}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape7}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape8}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.landscape9}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default WeddingCarousel;
