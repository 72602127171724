import React, { useEffect } from "react";
import "../CSS/about.css";
import images from "../../constants/images";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="app__about" id="about">
      <div className="app__about-content">
        <div className="app__about-text_container" data-aos="fade-right">
          <div className="app__about-header_container">
            <h1 className="app__about-header">O nas</h1>
            <div className="app__about-underscore"></div>
          </div>

          <p className="app__about-content_text">
            Cześć! Istnienie naszej kwiaciarni datujemy na połowę października
            2014 roku. Od samego początku stawiamy na selekcję najlepszy odmian
            kwiatów. Nasi klienci doceniają je tak samo, jak my.
          </p>
          <p className="app__about-content_text">
            Od 2021 roku jesteśmy w naszej nowej lokalizacji w samym centrum
            miasta na placu Jana Pawła II.
          </p>
          <p className="app__about-content_text">
            Oferujemy kwiaty cięte i doniczkowe w różnorodnych formach. To ty
            decydujesz czy chcesz klasyczny bukiet, czy może jego nowoczesne
            wydanie jako flowerbox. Przy okazji pochwalimy się, że jako pierwsi
            wprowadziliśmy flowerbowxy na nasz lokalny rynek!
          </p>

          <div className="app__about-btn_container">
            <button className="app__about-content_btn">
              <a className="app__about-content_btn-link" href="#offer">
                SPRAWDŹ NASZĄ PEŁNĄ OFERTĘ
              </a>
            </button>
          </div>

          <p className="app__about-content_text">
            Oferujemy przesyłki kwiatowe, które dowozimy na terenie Wąbrzeźna,
            ale i również do okolicznych wiosek. Poza tradycyjną sprzedażą
            bukietów zajmujemy się również dekorowaniem eventów, sal i
            kościołów.
          </p>
          <p className="app__about-content_text">
            Chętnie podejmujemy się florystycznych wyzwań, których realizacja
            dostarcza nam ogromną satysfakcję. Nieoceniona jest wdzięczność za
            zaufanie, którą dostajemy od naszych zadowolonych klientów.
          </p>
          <p className="app__about-content_text client-message">
            Do zobaczenia w Naszej-Waszej Azalii.
          </p>
        </div>
        <div className="app__about-image_container" data-aos="fade-left">
          <img className="app__about-image" src={images.about} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
