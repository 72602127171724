import "../CSS/navbar.css";
import images from "../../constants/images";
import React, { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <>
      <div className="app__navbar">
        <div className="app__navbar-logo_container">
          <img className="app__navbar-logo" src={images.logo} alt="logo" />
        </div>
        <div className="app__navbar-menu">
          <ul className="app__navbar-menu_list">
            <li className="app__navbar-menu_list-element">
              {" "}
              <a href="#home" className="navbar-link">
                Home
              </a>{" "}
            </li>
            <li className="app__navbar-menu_list-element">
              {" "}
              <a href="#about" className="navbar-link">
                {" "}
                O nas
              </a>
            </li>
            <li className="app__navbar-menu_list-element">
              {" "}
              <a href="#offer" className="navbar-link">
                Oferta
              </a>{" "}
            </li>
            <li className="app__navbar-menu_list-element">
              {" "}
              <a href="#delivery" className="navbar-link">
                Dostawy
              </a>{" "}
            </li>
            <li className="app__navbar-menu_list-element">
              <a href="#wedding" className="navbar-link">
                Śluby
              </a>
            </li>
            <li className="app__navbar-menu_list-element">
              {" "}
              <a href="#form" className="navbar-link">
                Kontakt
              </a>{" "}
            </li>
          </ul>
        </div>
        <div className="app__navbar-toggle">
          {toggleMenu ? (
            <RxCross2
              color="C591A6"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <IoIosMenu
              color="C591A6"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
        </div>
      </div>
      <div className="app__navbar-smallscreen">
        {toggleMenu && (
          <ul className="app__navbar-smallscreen_menu-list">
            <li className="app__navbar-smallscreen_menu-list_element">
              {" "}
              <a href="#home" className="navbar-link">
                Home
              </a>{" "}
            </li>
            <li className="app__navbar-smallscreen_menu-list_element">
              {" "}
              <a href="#about" className="navbar-link">
                O nas
              </a>
            </li>
            <li className="app__navbar-smallscreen_menu-list_element">
              {" "}
              <a href="#offer" className="navbar-link">
                Oferta
              </a>
            </li>
            <li className="app__navbar-smallscreen_menu-list_element">
              {" "}
              <a href="#delivery" className="navbar-link">
                Dostawy
              </a>
            </li>
            <li
              className="app__navbar-smallscreen_menu-list_element"
              href="#wedding"
            >
              {" "}
              <a href="#wedding" className="navbar-link">
                Śluby
              </a>
            </li>
            <li className="app__navbar-smallscreen_menu-list_element">
              {" "}
              <a href="#form" className="navbar-link">
                Kontakt
              </a>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Navbar;
