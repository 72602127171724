import React from "react";
import "../CSS/delivery.css";

const Delivery = () => {
  return (
    <div className="app__delivery" id="delivery">
      <h1 className="app__delivery-heading" data-aos="fade-up">
        Dostawy
      </h1>

      <div className="app__delivery-underscore" data-aos="fade-up"></div>
      <div className="app__delivery-content" data-aos="fade-up">
        <p className="app__delivery-text">
          Chcesz wywołać uśmiech i niezapomniane zaskoczenie bliskiej Ci osobie?
          Dostarczamy bukiety na terenie miasta i najbliższych okolic.
        </p>
        <button className="app__delivery-btn">
          {" "}
          <a className="app__delivery-btn_link" href="#footer">
            SKONTAKTUJ SIĘ Z NAMI W CELU OMÓWIENIA SZCZEGÓŁÓW.
          </a>
        </button>
      </div>
    </div>
  );
};

export default Delivery;
