import React from "react";
import OfferCarousel from "./OfferCarousel";
import images from "../../constants/images";
import "../CSS/offer.css";
import { Carousel } from "react-bootstrap";

const Offer = () => {
  return (
    <div className="app__offer" id="offer">
      <div className="app__offer-carousel_container">
        <Carousel fade className="app__offer-carousel">
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_eight}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_seven}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_eleven}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_thirteen}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_fourteen}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_six}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.carousel_one}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.portrait_one}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.portrait_two}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.portrait_three}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.portrait_four}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.portrait_5}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={images.portrait_6}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="app__offer-text_container">
        <h1 className="app__offer-heading">Oferta</h1>
        <div className="app__offer-underscore_container">
          <div className="app__offer-underscore"></div>
        </div>
        <p className="app__offer-point">Kwiaty cięte</p>
        <p className="app__offer-point">Bukiety</p>
        <p className="app__offer-point">Flowerboxy</p>
        <p className="app__offer-point">Kwiaty doniczkowe</p>
        <p className="app__offer-point">Florystyka pogrzebowa</p>
        <p className="app__offer-point">Śluby i wesela</p>
        <p className="app__offer-point">Artykuły dekoracyjne</p>
        <h2 className="app__offer-subheading">Dla firm i instytucji</h2>
        <p className="app__offer-point">Wydarzenia</p>
        <p className="app__offer-point">Dekoracja miejsca eventu</p>
        <p className="app__offer-point">Bukiety do wręczenia</p>
      </div>
      <img
        className="app__offer-decoration_image-left"
        src={images.decoration_extra}
        alt=""
      />
      <img
        className="app__offer-decoration_image-right"
        src={images.decoration_extra}
        alt=""
      />
    </div>
  );
};

export default Offer;
