import "./App.css";
import About from "./components/JS/About";
import Delivery from "./components/JS/Delivery";
import Hero from "./components/JS/Hero";
import Navbar from "./components/JS/Navbar";
import Offer from "./components/JS/Offer";
import Wedding from "./components/JS/Wedding";
import WeddingCarousel from "./components/JS/WeddingCarousel";
import Form from "./components/JS/Form";
import Footer from "./components/JS/Footer";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      <Offer />
      <Delivery />
      <Wedding />
      <WeddingCarousel />
      <Form />
      <Footer />
      <CookieConsent
        debug={true}
        style={{
          background: "#C591A6",
          padding: "0 3rem",
          fontFamily: "Playfair Display, serif",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        buttonStyle={{
          cursor: "pointer",
          padding: "6px 25px",
          fontFamily: "Playfair Display, serif",
          borderRadius: "3px",
          color: "#C591A6",
          backgroundColor: "#fff",
        }}
        buttonText="Rozumiem!"
      >
        Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
        poziomie. Dalsze korzystanie ze strony oznacza że zgadzasz się na ich
        użycie.
      </CookieConsent>
    </div>
  );
}

export default App;
