import React from "react";
import images from "../../constants/images";
import "../CSS/wedding.css";

const Wedding = () => {
  return (
    <div className="app__wedding" id="wedding" data-aos="fade-down">
      <div className="app__wedding-image_container">
        <div className="app__wedding-image_container-inner">
          <img
            className="app__wedding-image"
            src={images.wedding_three}
            alt="couple"
          />
        </div>
        <img
          className="app__wedding-decoration"
          src={images.decoration_extra}
          alt="decoration"
        />
        <img
          className="app__wedding-decoration two"
          src={images.decoration_extra}
          alt="decoration"
        />
      </div>
      <div className="app__wedding-text_container">
        <div className="app__wedding-heading_container">
          <h1 className="app__wedding-heading">Śluby</h1>
          <div className="app__wedding-underscore"></div>
        </div>
        <p className="app__wedding-para">
          Z wielką przyjemnością zaprojektujemy i wykonamy dekoracje na Wasz
          ślub. Stworzymy dekoracje, które uświetnią ten dzień i zapadną w
          Waszej oraz Waszych gości pamięci na długo.
        </p>
        <p className="app__wedding-para">
          Zajmujemy się kompleksową dekoracją ślubów i wesel. Oprócz kwiatów
          posiadamy w swojej ofercie ścianki, numery stołów, pod talerze,
          świeczniki. Możemy wypożyczyć dla Was obrusy i serwety oraz zadbać o
          inne elementy, które będą niezbędne w projekcie.
        </p>
        <p className="app__wedding-para">
          Jeżeli jesteście ciekawi naszej oferty, skontaktuje się przez
          formularz, przygotowany specjalnie dla Par Młodych. Dzięki niemu na
          naszej pierwszej konsultacji będziemy mogli przedstawić Wam naszą
          wizję dekoracji na Wasz dzień.
        </p>
        <div className="app__wedding-btn_container">
          <button className="app__wedding-btn">
            <a href="#form"> ODEZWIJCIE SIĘ DO NAS!</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Wedding;
